import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

  constructor(){}
  title = 'Spy Portal';
  private headerClass:string;

  ngAfterViewInit(): void {
    this.loadHeader();
    this.loadFooter();
  }

 loadHeader(){

    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.spymuseum.org/ajax/header/?local');
    xhr.onload = function() {
        if (xhr.status === 200) {

          var response = xhr.responseText;
          var html = document.createElement('html');
          html.innerHTML = response;

          self.headerClass = $(html).find("body")[0].getAttribute("class");

          var head = $(html).find("head")[0].innerHTML;
          var header = $(html).find("header");

          var div = $("<div class='"+self.headerClass+" injected injectedHeader'></div>");
          div.append(header[0]);
          div.css("visibility", "hidden");

          setTimeout(function(){  div.css("visibility", "visible"); }, 1000);

          $("header").replaceWith(div);
          $("head").append(head);
          
        }
        else {
            alert('Request failed.  Returned status of ' + xhr.status);
        }
    };
    xhr.send();
  }

 loadFooter(){

    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.spymuseum.org/ajax/footer/?local');
    xhr.onload = function() {
        if (xhr.status === 200) {

          var response = xhr.responseText;
          var html = document.createElement('html');
          html.innerHTML = response;

          var footerBody = $(html).find("body")[0];

          var div = $("<div class='"+self.headerClass+"'></div>");
          div.append(footerBody.innerHTML);
          div.css("visibility", "hidden");
          $("footer").replaceWith(div);

          setTimeout(function(){  div.css("visibility", "visible"); }, 1000);

        }
        else {
            alert('Request failed.  Returned status of ' + xhr.status);
        }
    };
    xhr.send();
  }

}
