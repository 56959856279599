import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  private loginForm:any;
  private isError:boolean;
  isSelected:boolean;
  rfidValue:string;
 

  constructor(private formBuilder: FormBuilder){}

  handleClick = (event) => {
    var element = event.srcElement;
    this.isSelected = element == document.getElementById("rfidInput");
  }

  ngOnInit(){
    this.loginForm=this.formBuilder.group({
      rfid:['', Validators.required]
    })

    document.addEventListener("click", this.handleClick);
}

  onSubmit(form) { 

    var xmlhttp = new XMLHttpRequest();
    var app = this;
    var dataPath =  "https://undercovermission-debrief.spymuseum.org/" + form.value.rfid + ".json";

    xmlhttp.onreadystatechange = function() {
      if (this.readyState == 4){
        if(this.status ==  200) {
          var profilePath = "https://undercovermission.spymuseum.org/agent/" + form.value.rfid;
          window.location.href = profilePath;
        }else{
          app.isError = true;
        }
      }
    };

    xmlhttp.open("GET", dataPath, true);
    xmlhttp.send();
   }
}
